import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1ee56c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "miniProgress" }
const _hoisted_2 = { class: "progressWrap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tooltip = _resolveComponent("a-tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_a_tooltip, { title: _ctx.targetLabel }, {
      default: _withCtx(() => [
        _createElementVNode("div", {
          class: "target",
          style: _normalizeStyle({ left: _ctx.target ? `${_ctx.target}%` : undefined })
        }, [
          _createElementVNode("span", {
            style: _normalizeStyle({ backgroundColor: _ctx.color || undefined })
          }, null, 4),
          _createElementVNode("span", {
            style: _normalizeStyle({ backgroundColor: _ctx.color || undefined })
          }, null, 4)
        ], 4)
      ]),
      _: 1
    }, 8, ["title"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: "progress",
        style: _normalizeStyle({
          backgroundColor: _ctx.color || undefined,
          width: _ctx.percent ? `${_ctx.percent}%` : undefined,
          height: (_ctx.strokeWidth && `${_ctx.strokeWidth}px`) || undefined,
        })
      }, null, 4)
    ])
  ]))
}