
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    target: {
      type: [Number, String],
      default: () => undefined,
    },
    targetLabel: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'rgb(19, 194, 194)',
    },
    strokeWidth: {
      type: Number,
      default: 0,
    },
    percent: {
      type: Number,
      default: 0,
    },
  },
});
